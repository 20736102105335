import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PhoneIcon, MailIcon } from "@heroicons/react/solid"

const Employee = ({ employee }) => {
  const initials = employee.name
    .split(" ")
    .map(t => t[0].toUpperCase())
    .join("")
  return (
    <a className="hoverable block" href={`mailto:${employee.email}`}>
      <div className="flex">
        <div className="rounded w-24 h-24 bg-brand-300 mr-4 flex items-center justify-center flex-shrink-0">
          {employee.image ? (
            <GatsbyImage
              image={getImage(employee.image.staticFile)}
              alt={initials}
              imgClassName="rounded"
            />
          ) : (
            <span className="text-xl font-bold text-brand-900">{initials}</span>
          )}
        </div>
        <div>
          <div className="heading-sm mb-0">{employee.name}</div>
          <div className="font-semibold text-sm mt-0">{employee.position}</div>
          <div className="text-sm flex items-center mt-0">
            <MailIcon className="w-4 h-4 mr-1 text-gray-500" /> {employee.email}
          </div>
          <div className="text-sm flex items-center mt-0">
            <PhoneIcon className="w-4 h-4 mr-1 text-gray-500" />{" "}
            {employee.phone}
          </div>
        </div>
      </div>
    </a>
  )
}

export default Employee
