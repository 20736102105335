import { graphql } from "gatsby"
import React from "react"
import SectionRenderer from "../components/sections"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Employee from "../components/elements/employee"

export const query = graphql`
  query KongsbergContactPageQuery {
    strapi {
      about: kongsbergAbout {
        employeeSectionSubTitle
        employeeSectionTitle
        employees {
          email
          name
          phone
          position
          image {
            alternativeText
            id
            staticFile {
              childImageSharp {
                gatsbyImageData(width: 96, height: 96, placeholder: BLURRED)
              }
            }
          }
        }
        subTitle
        title
        preTitle
        sections {
          __typename
          ... on Strapi_ComponentSectionsHeading {
            large
            sub
            heading
          }
          ... on Strapi_ComponentSectionsSimpleShowCase {
            alignImageLeft
            badge
            bigger
            heading
            sub
            videoLink
            action {
              link
              text
            }
            image {
              id
              alternativeText
              staticFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
          ... on Strapi_ComponentSectionsContainedShowCase {
            id
            alignLeft
            heading
            text
            action {
              link
              text
            }
            image {
              id
              alternativeText
              staticFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  }
`

const ContactPage = ({
  data: {
    strapi: { about },
  },
}) => {
  return (
    <Layout>
      <SEO title={about.title} />
      <div className="bg-brand-100 relative">
        <div className="absolute z-0 top-0 square-float flex justify-center w-full h-full overflow-hidden">
          <div className="absolute square bg-brand-200" />
          <div className="absolute square bg-brand-50" />
        </div>

        <div className="container relative mx-auto z-10">
          <div className="relative z-10 pt-48 pb-32 text-center">
            <div className="heading-sm mb-0">{about.preTitle || "Om os"}</div>
            <h1 className="max-w-4xl mx-auto heading-xl">{about.title}</h1>
            <p
              className="max-w-4xl mx-auto text-2xl"
              dangerouslySetInnerHTML={{
                __html: about.subTitle.replace(/\n/g, "<br />"),
              }}
            />
            <div className="mt-8">
              <a href="tel:+4570702063" className="btn-secondary">
                +45 7070 2063
              </a>
              <a
                href="mailto:salg@artwork-systems.dk"
                className="btn-secondary ml-2"
              >
                salg@artwork-systems.dk
              </a>
            </div>
          </div>
        </div>
      </div>

      <SectionRenderer sections={about.sections} />

      <div className="container mx-auto my-24">
        <div className="block mx-auto w-16 bg-brand-500 h-1.5 text-center mb-3" />
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="heading-lg">{about.employeeSectionTitle}</h2>
          <p
            className="text-lg"
            dangerouslySetInnerHTML={{
              __html: about.employeeSectionSubTitle.replace(/\n/g, "<br />"),
            }}
          />
        </div>
        <div className="grid grid-cols-1 max-w-md lg:grid-cols-2 lg:max-w-4xl mt-6 mx-auto">
          {about.employees.map((employee, i) => (
            <Employee key={i} employee={employee} />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
